<template>
	<div class="profile-show">
		<div class="flex justify-between">
			<h1 class="profile-name-title">Settings: {{ name }}</h1>
			<button
				class="profile-header-btn"
				data-tooltip="Set Schedule"
				aria-label="Set Schedule"
				data-position="bottom"
				@click.prevent="onScheduleClick"
			>
				<font-awesome-icon
					fixed-width
					:icon="['fal', 'clock']"
					aria-hidden="true"
				/>
			</button>
		</div>
		<form
			v-if="partner"
			class="profile-container"
			@submit.prevent="onSubmit"
		>
			<div class="profile-section profile-form">
				<h2 class="address-name-title m-0 pl-0 mb-2">
					Configuration
				</h2>
				<div class="form-group">
					<label class="form-label form-group-title-new">
						Practice Name
					</label>
					<div class="nonEditable-text settings-input">
						{{ form.name }}
					</div>
				</div>

				<div class="form-group">
					<label class="form-label form-group-title-new">
						Forwarding Number
					</label>
					<div class="nonEditable-text settings-input">
						{{ partner.twilio_phone_number | formatPhoneNumber }}
					</div>
				</div>

				<div class="profile-section-single-line-details">
					<div class="form-group-profile-section-single-line-details">
						<label
							for="office_phone_number"
							class="form-label form-group-title-new"
						>
							Office Phone Number
						</label>
						<input
							id="office_phone_number"
							v-model="form.office_phone_number"
							type="text"
							name="office_phone_number"
							class="form-input"
							placeholder="Phone number"
						/>
					</div>

					<div class="form-group-profile-section-single-line-details">
						<label
							for="office_fax_number"
							class="form-label form-group-title-new"
						>
							Office Fax Number
						</label>
						<input
							id="office_fax_number"
							v-model="form.office_fax_number"
							type="text"
							name="office_fax_number"
							placeholder="Fax Number"
							class="form-input"
						/>
					</div>
				</div>

				<div class="form-group">
					<label
						for="fallback_email_1"
						class="form-label form-group-title-new mt-3"
					>
						Fallback Notification Email
					</label>
					<input
						id="fallback_email_1"
						v-model="form.fallback_email"
						required
						type="text"
						name="fallback_email_1"
						class="form-input"
						placeholder="Fallback notification email"
					/>
				</div>
				<div class="profile-section-single-line-details">
					<div class="form-group-profile-section-single-line-details">
						<label
							for="fallback_phone_number"
							class="form-label form-group-title-new"
						>
							Fallback Notification Phone Number
						</label>
						<input
							id="fallback_phone_number"
							v-model="form.fallback_phone_number"
							required
							pattern="^[2-9]\d{9}$"
							title="The fallback phone number field must be a valid 10 digit number"
							type="text"
							name="fallback_phone_number"
							class="form-input"
							placeholder="Notification phone number"
						/>
					</div>
					<div class="form-group-profile-section-single-line-details">
						<label
							for="timezone"
							class="form-label form-group-title-new"
						>
							Timezone
						</label>
						<div class="relative">
							<select
								id="timezone"
								v-model="form.timezone"
								name="timezone"
								placeholder="Select a timezone"
								class="form-input"
								:class="[
									form.timezone
										? 'text-gray-700'
										: 'text-gray-500',
								]"
							>
								<option :value="null" disabled
									>Select a timezone</option
								>
								<option
									v-for="timezone in timezones"
									:key="timezone"
									:value="timezone"
								>
									{{ timezone }}
								</option>
							</select>
							<div
								class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
							>
								<font-awesome-icon
									class="fill-current h-4 w-4 mt-4 mr-2"
									:icon="['far', 'chevron-down']"
								></font-awesome-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="address-section profile-form">
				<h2 class="address-name-title m-0 pl-0 mb-2">
					Address
				</h2>
				<div class="form-group">
					<label
						for="address_1"
						class="form-label form-group-title-new"
					>
						Street Address
					</label>
					<input
						id="address_1"
						v-model="form.address_1"
						type="text"
						name="address_1"
						class="form-input"
						placeholder="Street Address"
					/>
				</div>
				<div class="form-group">
					<label
						for="address_2"
						class="form-label form-group-title-new"
					>
						Address Line 2
					</label>
					<input
						id="address_2"
						v-model="form.address_2"
						type="text"
						name="address_2"
						class="form-input"
						placeholder="Address Line 2"
					/>
				</div>
				<div class="profile-section-single-line-details">
					<div class="form-group-profile-section-single-line-details">
						<label
							for="city"
							class="form-label form-group-title-new"
						>
							City
						</label>
						<input
							id="city"
							v-model="form.city"
							type="text"
							name="city"
							class="form-input"
							placeholder="City"
						/>
					</div>
					<div class="form-group-profile-section-single-line-details">
						<label
							for="state_province"
							class="form-label form-group-title-new"
						>
							State Or Province
						</label>
						<input
							id="state_province"
							v-model="form.state_province"
							type="text"
							name="state_province"
							class="form-input"
							placeholder="State Or Province"
						/>
					</div>
				</div>
				<div class="profile-section-single-line-details">
					<div class="form-group-profile-section-single-line-details">
						<label
							for="country"
							class="form-label form-group-title-new"
						>
							Country
						</label>
						<input
							id="country"
							v-model="form.country"
							type="text"
							name="country"
							class="form-input"
							placeholder="Country"
						/>
					</div>
					<div class="form-group-profile-section-single-line-details">
						<label
							for="postal_code"
							class="form-label form-group-title-new"
						>
							Postal Code
						</label>
						<input
							id="postal_code"
							v-model="form.postal_code"
							type="text"
							name="postal_code"
							class="form-input"
							placeholder="Postal Code"
						/>
					</div>
				</div>
			</div>
			<div class="voicemail-section profile-form">
				<h2 class="voicemail-name-title m-0 mb-2 p-0 pb-2">
					Secondary Voicemail
					<span class="space-x-4">
						<button
							type="button"
							aria-label="Filter reports"
							data-position="top"
							data-tooltip="Help"
							@click.stop.prevent="onClickInfo"
						>
							<font-awesome-icon
								class="address-icon"
								:icon="['far', 'question-circle']"
							/>
						</button>
						<button
							type="button"
							aria-label="Filter reports"
							data-position="top"
							data-tooltip="Copy Settings"
							@click.stop.prevent="copyVoicemailData"
						>
							<font-awesome-icon
								class="address-icon"
								:icon="['far', 'address-card']"
							/>
						</button>
					</span>
				</h2>
				<div class="voicemail-section-single-line-details mb-2">
					<div
						class="form-group-voicemail-section-single-line-details mb-3"
					>
						<label class="form-label mb-0">
							Voicemail Retrieval Number
						</label>
						<div class="nonEditable-text settings-input">
							{{
								partner.global_rsm_phone_number
									| formatPhoneNumber
							}}
						</div>
					</div>
					<div
						class="form-group-voicemail-section-single-line-details"
					>
						<label class="form-label mb-0">
							Mailbox Number
						</label>
						<div class="nonEditable-text settings-input">
							{{
								partner.twilio_phone_number | formatPhoneNumber
							}}
						</div>
					</div>
					<div
						class="form-group-voicemail-section-single-line-details"
					>
						<label for="pass_code" class="form-label mb-0"
							>Passcode</label
						>
						<div class="relative">
							<input
								id="pass_code"
								v-model="form.passcode"
								autocomplete="new-password"
								:type="showPasscode ? 'text' : 'password'"
								name="pass_code"
								class="form-input"
								placeholder="Passcode"
								maxlength="6"
								required
								@input="validatePasscode"
							/>
							<button
								type="button"
								data-tooltip="Show Passcode"
								class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-700"
								@click="showPasscode = !showPasscode"
							>
								<font-awesome-icon
									:icon="[
										showPasscode ? 'fas' : 'fas',
										showPasscode ? 'eye-slash' : 'eye',
									]"
								/>
							</button>
						</div>
						<div
							v-if="passcodeErrorMessage"
							class="text-red-500 text-sm mt-2"
						>
							{{ passcodeErrorMessage }}
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="email_notification" class="form-label ml-1">
						Email Notifications
					</label>
					<div class="input-tag-container">
						<div
							class="tag-container"
							:class="{ empty: fallbackEmailTags.length === 0 }"
						>
							<div
								v-for="(email, index) in fallbackEmailTags"
								:key="index"
								class="tag"
							>
								{{ email }}
								<span
									class="close mr-1"
									@click="removeEmail(index)"
									>x</span
								>
							</div>
							<input
								id="email_notifications"
								v-model="newEmail"
								type="email"
								class="form-input-fallback_email"
								:placeholder="
									fallbackEmailTags.length === 0
										? 'Add email...'
										: 'Add emails...'
								"
								@keydown.enter.prevent="addEmail"
								@keydown="handleBackspace"
								@keypress="checkForComma($event)"
								@input="debounceFilterEmailSuggestions($event)"
								@blur="addEmail"
							/>
						</div>
						<ul
							v-if="emailSuggestions.length"
							class="email-suggestions"
						>
							<li
								v-for="suggestion in emailSuggestions"
								:key="suggestion"
								class="suggestion-item"
								@mousedown="selectEmail(suggestion)"
							>
								{{ suggestion }}
							</li>
						</ul>
					</div>
					<div v-if="emailErrorMessage" class="text-red-500 text-sm">
						{{ emailErrorMessage }}
					</div>
				</div>
			</div>
			<div class="form-save-button-container">
				<div class="flex justify-end">
					<button type="submit" class="btn btn-outline-success ml-2">
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'paper-plane']"
						/>
						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
			</div>
		</form>
		<edit-schedule-panel :show="panel" @close="panel = false" />
	</div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EditSchedulePanel from '@/components/Panels/EditSchedulePanel.vue'
import intercomConfig from '@/config/intercom'

/**
 * Get the empty partner form object.
 *
 * @return {Object}
 */
const getFormObject = () => {
	return {
		name: '',
		office_phone_number: '',
		office_fax_number: '',
		address_1: '',
		address_2: '',
		city: '',
		state_province: '',
		country: '',
		postal_code: '',
		fallback_email: '',
		fallback_phone_number: '',
		timezone: '',
		rsm_fallback_email: '',
		passcode: '',
	}
}

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		EditSchedulePanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the partner's name.
		 *
		 * @return {String}
		 */
		name() {
			return this.partner?.name || ''
		},

		fallbackEmailTags: {
			get() {
				let emails = this.form.rsm_fallback_email
				if (emails && emails.trim()) {
					return emails.split(/,|;|\s+/).filter(email => email.trim())
				}

				return []
			},
			set(newEmails) {
				this.form.rsm_fallback_email = newEmails.join(', ')
			},
		},

		partnerId() {
			return parseInt(this.$route.params.partner)
		},

		...mapGetters({
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		debounce(fn, delay) {
			let timeoutID
			return function(...args) {
				if (timeoutID) {
					clearTimeout(timeoutID)
				}
				timeoutID = setTimeout(() => {
					fn.apply(this, args)
				}, delay)
			}
		},
		/**
		 * Get the form field names/keys.
		 *
		 * @return {Array}
		 */
		fields() {
			return Object.keys(this.form)
		},

		/**
		 * Fill the form with the partner's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (!this.partner) {
				return this.reset()
			}
			this.fields().forEach(key => {
				if (
					this.partner[key] !== null &&
					typeof this.partner[key] !== 'undefined'
				) {
					if (
						[
							'office_phone_number',
							'office_fax_number',
							'fallback_phone_number',
						].includes(key)
					) {
						this.form[key] = this.partner[key].replace(/^\+1/, '')
					} else {
						this.form[key] = this.partner[key]
					}
				}
			})
			this.passcodeErrorMessage = ''
			this.emailErrorMessage = ''
			this.showPasscode = false
			this.form.rsm_fallback_email =
				this.partner.rsm_fallback_email ||
				this.partner.fallback_email ||
				''
		},

		/**
		 * Handle the schedule click event.
		 *
		 * @return {void}
		 */
		onScheduleClick() {
			this.panel = true
		},

		removeLastEmail() {
			if (this.fallbackEmailTags.length > 0) {
				this.fallbackEmailTags.pop()
				this.updateFormData()
			}
		},

		handleBackspace(event) {
			if (
				(event.key === 'Backspace' || event.which === 8) &&
				!this.newEmail
			) {
				this.removeLastEmail()
			}
		},

		updateFormData() {
			this.form.rsm_fallback_email = this.fallbackEmailTags.join(', ')
		},

		removeEmail(index) {
			const emails = this.fallbackEmailTags.filter((_, i) => i !== index)
			this.fallbackEmailTags = emails
			if (emails.length === 0) {
				this.form.rsm_fallback_email = null
			} else {
				this.form.rsm_fallback_email = emails.join(', ')
			}
		},

		addEmail() {
			const emails = this.newEmail.split(/[;, \t\n]+/)
			emails.forEach(email => {
				if (this.validateEmail(email)) {
					const trimmedEmail = email.trim()
					if (trimmedEmail) {
						const newEmails = trimmedEmail.split(/[;, \t\n]+/)
						const existingEmails = (
							this.form.rsm_fallback_email || ''
						)
							.trim()
							.split(/[;, \t\n]+/)
							.filter(email => email.trim())
						const allEmails = [...existingEmails, ...newEmails]
						this.form.rsm_fallback_email = allEmails.join(', ')
						if (!this.form.rsm_fallback_email) {
							this.form.fallback_email = this.form.rsm_fallback_email
						}
					}
				}
			})
			this.newEmail = ''
			this.emailSuggestions = []
		},

		async fetchEmailSuggestions(searchTerm) {
			if (!searchTerm) return

			const partnerId = parseInt(this.$route.params.partner)
			const apiUrl = `${process.env.VUE_APP_API_URL}/api/v4/partners/${partnerId}/search?field=email&role=office_manager&search_value=${searchTerm}`

			try {
				const response = await fetch(apiUrl, {
					headers: {
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
				})

				if (!response.ok) {
					throw new Error('Failed to fetch email suggestions')
				}

				const data = await response.json()

				this.emailSuggestions = data.map(suggestion =>
					suggestion.trim()
				)
			} catch (error) {
				console.error(
					'fetchEmailSuggestions: Error fetching email suggestions',
					error
				)
			}
		},

		validateEmail(email) {
			if (!email) {
				this.emailErrorMessage = ''
				return true
			}

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			if (!emailRegex.test(email)) {
				this.emailErrorMessage = 'Please enter a valid email address.'
				return false
			}

			if (this.fallbackEmailTags.includes(email)) {
				this.emailErrorMessage =
					'Duplicate email address. Please enter a unique email.'
				return false
			}

			if (/[A-Z]/.test(email)) {
				this.emailErrorMessage =
					'Email addresses cannot contain capital letters.'
				return false
			}

			this.emailErrorMessage = ''
			return true
		},

		checkForComma(event) {
			if (event.key === ',') {
				event.preventDefault()
				this.addEmail()
			}
		},

		isSequential(passcode) {
			for (let i = 0; i < passcode.length - 2; i++) {
				if (
					parseInt(passcode[i]) + 1 === parseInt(passcode[i + 1]) &&
					parseInt(passcode[i + 1]) + 1 === parseInt(passcode[i + 2])
				) {
					return true
				}
			}
			return false
		},

		isRepeating(passcode) {
			for (let i = 0; i < passcode.length - 2; i++) {
				if (
					passcode[i] === passcode[i + 1] &&
					passcode[i + 2] === passcode[i]
				) {
					return true
				}
			}
			return false
		},

		async selectEmail(suggestion) {
			const selectedEmail = suggestion

			if (this.validateEmail(selectedEmail)) {
				const newEmails = selectedEmail.split(/[;, \t\n]+/)
				const existingEmails = (this.form.rsm_fallback_email || '')
					.trim()
					.split(/[;, \t\n]+/)
					.filter(email => email.trim())
				const allEmails = [...existingEmails, ...newEmails]
				this.form.rsm_fallback_email = allEmails.join(', ')
				this.newEmail = ''
				this.emailErrorMessage = ''
			} else {
				console.log(
					'selectEmail: Email validation failed for',
					selectedEmail
				)
			}
		},

		async filterEmailSuggestions() {
			const searchTerm = this.newEmail.toLowerCase().trim()
			if (searchTerm.length >= 2) {
				this.emailSuggestions = this.allEmails.filter(email => {
					const lowerCaseEmail = email.toLowerCase()
					return (
						lowerCaseEmail.includes(searchTerm) &&
						!this.fallbackEmailTags.includes(email)
					)
				})
			} else {
				this.emailSuggestions = []
			}
		},

		debounceFilterEmailSuggestions: function(event) {
			const searchTerm = event.target.value.trim()
			this.debounce(function() {
				if (searchTerm.length >= 2) {
					this.fetchEmailSuggestions(searchTerm)
				} else {
					this.emailSuggestions = []
				}
			}, 300).apply(this)
		},

		validatePasscode() {
			const passcode = this.form.passcode

			if (passcode.length < 6) {
				this.passcodeErrorMessage =
					'Passcode must be at least 6 digits long.'
				return false
			}

			if (this.isSequential(passcode)) {
				this.passcodeErrorMessage =
					'Passcode cannot contain 3 or more sequential digits.'
				return false
			}

			if (this.isRepeating(passcode)) {
				this.passcodeErrorMessage =
					'Passcode cannot contain 3 or more repeating digits.'
				return false
			}

			this.passcodeErrorMessage = ''
			return true
		},

		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async onSubmit() {
			if (!this.validatePasscode()) {
				this.$alert.error(this.passcodeErrorMessage)
				return
			}
			if (!this.form.rsm_fallback_email && this.form.fallback_email) {
				this.form.rsm_fallback_email = this.form.fallback_email
			}
			if (this.form.rsm_fallback_email) {
				const emails = this.form.rsm_fallback_email
					.split(/,|;|\n|\s/)
					.map(email => email.trim())
					.filter(email => email)
				const duplicates = this.findDuplicates(emails)
				if (duplicates.length > 0) {
					this.$alert.error(
						`Duplicate emails found: ${duplicates.join(', ')}`
					)
					return
				}

				this.form.rsm_fallback_email = emails.join(', ')
			}
			try {
				await this.update({
					...this.form,
					id: this.partner.id,
				})
				this.$alert.success('partners.success')
			} catch (e) {
				this.$alert.error('partners.error')
			}
		},

		findDuplicates(emails) {
			const duplicates = []
			const emailMap = new Map()

			emails.forEach(email => {
				const normalizedEmail = email.toLowerCase()
				if (emailMap.has(normalizedEmail)) {
					duplicates.push(email)
				} else {
					emailMap.set(normalizedEmail, true)
				}
			})
			return duplicates
		},

		/**
		 * Reset the form back to its initial state.
		 *
		 * @return {void}
		 */
		reset() {
			this.form = getFormObject()
		},

		/**
		 * Copy the voicemail data to the clipboard.
		 *
		 * @return {void}
		 */
		copyVoicemailData() {
			if (
				!this.form.rsm_fallback_email ||
				!this.validatePasscode(this.form.passcode) ||
				!this.form.fallback_email
			) {
				this.$alert.error(
					'Please fill in the email notifications and enter a valid passcode to copy settings.'
				)
				return
			}

			const formattedGlobalRsmPhoneNumber = this.partner.global_rsm_phone_number
				.substring(2)
				.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
			const formattedTwilioPhoneNumber = this.partner.twilio_phone_number
				.substring(2)
				.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

			const voicemailData = `
Secondary Voicemail Instructions

The Secondary Voicemail system ensures that all messages are captured should there be a momentary service interruption with our partners, platform, or your IVR. When a message is left, an email with instructions to retrieve the message is sent to your Email Notification recipients. The configuration and instructions for your mailbox number, passcode, and email recipients are defined in your Office Manager Dashboard, Settings under Secondary Voicemail.

Important Notes:
- Multiple users can retrieve messages at the same time.
- Clarus support does not have your passcode.
- Messages will not appear in your Office Dashboard or Clarus App at this time.

Configuration of your Secondary Voicemail service:

Update your Email Notification recipients to a list of important users who may need to be notified if a message is received, such as your phone staff or providers. This service supports more than one recipient. The initial email is copied from your fallback notification email. Click the Card icon to copy them to your clipboard for printing, share with your providers, or save them to shared contacts, as they might not be available during an interruption.

To retrieve your messages:
1. Dial the voicemail retrieval number: ${formattedGlobalRsmPhoneNumber}.
2. Enter your ten-digit mailbox number: ${formattedTwilioPhoneNumber}.
3. Enter your passcode: ${this.form.passcode}.
4. You can play and re-play each message until you have captured all the details.
5. Once you play the next message, you will not be able to listen to the previous or older messages.
                `

			navigator.clipboard
				.writeText(voicemailData)
				.then(() => {
					this.$alert.success(
						'Secondary Voicemail data copied to clipboard'
					)
				})
				.catch(err => {
					console.error('An error occurred:', err)
					this.$alert.error('Failed to copy Secondary Voicemail data')
				})
		},

		onClickInfo() {
			console.log(
				'intercomConfig.RSM_ARTICLE_ID --- ',
				intercomConfig.RSM_ARTICLE_ID
			)
			if (window.Intercom && intercomConfig.RSM_ARTICLE_ID) {
				window.Intercom('showArticle', intercomConfig.RSM_ARTICLE_ID)
			}
		},

		...mapActions({ update: 'partners/update' }),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the active partners.
		 */
		partners: {
			immediate: true,
			handler: 'fillForm',
		},

		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.reset()
				this.fillForm()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.timezones = moment.tz.zonesForCountry('us')
		if (!this.form.rsm_fallback_email && this.form.fallback_email) {
			this.form.rsm_fallback_email = this.form.fallback_email
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			form: getFormObject(),
			panel: false,
			timezones: [],
			showPasscode: false,
			newEmail: '',
			errorMessage: '',
			passcodeErrorMessage: '',
			emailSuggestions: [],
			emailErrorMessage: '',
			allEmails: [],
		}
	},
}
</script>

<style scoped>
/* Styles for tags */
.tag-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border: 1px solid #cbd5e0;
	padding: 0.5rem 0.75rem;
	border-radius: 0.5rem;
	min-height: 2.5rem;
	width: 100%;
}

.tag-container.empty {
	padding: 0.5rem 0.75rem;
	border-radius: 0.5rem;
	min-height: 2.5rem;
}

.tag {
	background-color: #e2e8f0;
	color: #2d3748;
	padding: 0.25rem 0.5rem;
	border-radius: 10px;
	margin: 0.25rem;
}

.tag .close {
	cursor: pointer;
	margin-left: 0.5rem;
}

.form-input {
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	border: 1px solid #cbd5e0;
	margin-top: 0.5rem;
}

.input-tag-container {
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
	width: 100%;
}

.form-input-fallback_email {
	flex: 1;
	min-width: 150px;
	border: none;
	padding: 0.5rem 0;
}

.form-input-fallback_email:focus {
	outline: none;
}

.form-group-title-new {
	margin-bottom: 0 !important;
	margin-left: 4px !important;
}

.settings-input {
	height: 42px !important;
	padding: 0.5rem 1rem !important;
	border-radius: 0.5rem;
	border: 1px solid #cbd5e0;
	margin-top: 0.5rem;
}

.suggestions {
	position: absolute;
	background: white;
	border: 1px solid #ccc;
	max-height: 150px;
	overflow-y: auto;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0 0 2px 0;
}

.suggestions li {
	padding: 8px;
	cursor: pointer;
}

.suggestions li:hover {
	background: #f0f0f0;
}

.suggestions-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.suggestions-list li {
	cursor: pointer;
	padding: 5px;
}

.suggestions-list li:hover {
	background-color: #eee;
}

.email-suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	width: calc(100% - 2px);
	background: white;
	border: 1px solid #ccc;
	border-top: none;
	max-height: 200px;
	overflow-y: auto;
	z-index: 1000;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 0.5rem;
}

.suggestion-item {
	padding: 10px;
	cursor: pointer;
}

.suggestion-item:hover {
	background-color: #f0f0f0;
}
</style>
